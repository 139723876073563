<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Raport</h3>
        </header>
        <div class="p-fluid p-formgrid p-grid" v-if="this.content">
            <div class="p-field p-col-12" v-if="this.reportType === 'woda'">
                <label style="color: red">Zużycie wody</label>
                <InputNumber id="data.data" style="color: red" v-model="content.data.waterConsumption" maxFractionDigits="2" disabled/>
            </div>
            <div class="p-field p-col-12" v-if="this.reportType === 'woda'">
                <label style="color: red">Średnie zużycie wody na mieszkańca</label>
                <InputNumber id="data.data" style="color: red" v-model="content.data.waterConsumptionPerPerson" maxFractionDigits="2" disabled/>
            </div>
            <div class="p-field p-col-12" v-if="this.reportType === 'woda'">
                <label style="color: red">Deklarowan liczba mieszkańców</label>
                <InputNumber id="data.data" style="color: red" v-model="content.data.declaredResidents" maxFractionDigits="2" disabled/>
            </div>
            <div class="p-field p-col-12" v-if="this.reportType !== 'woda'">
                <label style="color: red">Liczba osób zadeklarowana</label>
                <InputText id="data.counted" style="color: red" v-model="content.data.declared" disabled/>
            </div>
            <div class="p-field p-col-12" v-if="this.reportType !== 'woda'">
                <label style="color: red">Liczba osób wyliczona</label>
                <InputText id="data.counted" style="color: red" v-model="content.data.counted" disabled/>
            </div>
            <div class="p-field p-col-12" v-if="this.reportType !== 'woda' && content.data.detailedUnits">
                <label>Nazwy jednostek z których wyliczona jest liczba osób do raportu</label><br/>
                <label v-if="content.data.detailedUnits.housing">Dodatek mieszkaniowy</label>
                <ul v-if="content.data.detailedUnits.housing">
                    <li v-for="unit in content.data.detailedUnits.housing.units" :key="unit">{{unit}}</li>
                </ul>
                <label v-if="content.data.detailedUnits.social">MOPS</label>
                <ul v-if="content.data.detailedUnits.social">
                    <li v-for="unit in content.data.detailedUnits.social.units" :key="unit">{{unit}}</li>
                </ul>
                <label v-if="content.data.detailedUnits.government">Jednostki samorządowe</label>
                <ul v-if="content.data.detailedUnits.government">
                    <li v-for="unit in content.data.detailedUnits.government.units" :key="unit">{{unit}}</li>
                </ul>
                <label v-if="content.data.detailedUnits.education">Jednostki oświaty</label>
                <ul v-if="content.data.detailedUnits.education">
                    <li v-for="unit in content.data.detailedUnits.education.units" :key="unit">{{unit}}</li>
                </ul>
            </div>
            <div class="p-field p-col-12">
                <label for="quarter">Kwartał</label>
                <InputText id="quarter" v-model="content.quarter" disabled/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="year">Rok</label>
                <InputText id="year" v-model="content.year" disabled/>
            </div>
            <div class="p-field p-col-12">
                <label for="place">Miejscowość</label>
                <InputText id="place" type="text" v-model="content.place" disabled/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="street">Ulica</label>
                <InputText id="street" type="text" v-model="content.street" disabled/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="zipCode">Kod pocztowy</label>
                <InputText id="zipCode" type="text" v-model="content.zipCode" disabled/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="buildingNumber">Numer budynku</label>
                <InputText id="buildingNumber" type="text" v-model="content.buildingNumber" disabled/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="apartmentNumber">Numer mieszkania</label>
                <InputText id="apartmentNumber" type="text" v-model="content.apartmentNumber" disabled/>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-6 fa-pull-right">
            <Button type="button" class="p-button-secondary" label="Wróć" v-on:click="goBack"/>
        </div>
    </div>
</template>

<script>
    import ReportService from "../../services/reports.service";

    export default {
        inject: ['global'],
        name: "ReportsDetailsShow",
        data() {
            return {
                content: undefined,
                reportType: 'default'
            };
        },
        methods: {
            goBack: function () {
                if(this.reportType === 'woda'){
                    this.$router.push({path: `${this.global.state.instancePatch}/raporty/woda/lista/${this.$route.params.reportId}/lista`});
                } else {
                    this.$router.push({path: `${this.global.state.instancePatch}/raporty/deklaracje/lista/${this.$route.params.reportId}/lista`});
                }
            }
        },
        mounted() {
            this.reportType = this.$route.params.type === 'woda' ? 'woda' : 'default';
            if (this.reportType === 'woda') {
                ReportService.getReportDetailsShowWater(this.$route.params.reportId, this.$route.params.showId).then(
                    (response) => {
                        this.content = response.data;
                        this.content.data.waterConsumption = this.content.data.waterConsumption ? this.content.data.waterConsumption : 0;
                        this.content.data.waterConsumptionPerPerson = this.content.data.waterConsumptionPerPerson ? this.content.data.waterConsumptionPerPerson : 0;
                        },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            } else {
                ReportService.getReportDetailsShow(this.$route.params.reportId, this.$route.params.showId).then(
                    (response) => {
                        this.content = response.data;
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
        }
    };
</script>
